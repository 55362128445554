@import '@back-at-you-inc/bay-ui/dist/css/theme_default.min.css';
@import '@back-at-you-inc/bay-ui/dist/index.css';

@import "./components/pages/services/index.scss";
@import "./components/pages/mls/index.scss";
@import "./components/pages/domain/index.scss";

.spin {
	animation: spin 2s infinite linear;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(359deg);
	}
}
.cursor-pointer {
	cursor: pointer;
}

#BaySidebar.pro-sidebar {
	left: 0;
}