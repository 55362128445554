.services-page {
	.bay-selection-counter {
		--bs-badge-padding-x: 0.65em;
		--bs-badge-padding-y: 0.35em;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: auto;
		min-width: 20px;
		height: 20px;
		border-radius: 3px;
		background-color: var(--bs-primary);
		color: var(--bs-white);
		font-size: 13px;
		padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
	}
}


.services-search-container {
	display: flex;
	align-items: center;
	border: 1px solid #ccc;
	width: 500px;
	padding-left: 9px;
	font-size: 14px;
	min-height: 38px;
	background-color: white;
	border-radius: 5px;
}

.search-input {
	border: none;
	outline: none;
	width: 100%;
	padding-left: 7px;
}


.custom-modal-content {
	padding: 1rem;

	.modal-label {
		font-size: 14px;
		margin-bottom: 0.5rem;
		text-transform: capitalize;
		font-weight: 500;
		color: #2f323e;
	}
}

.ka-thead-row {
	border-bottom: solid 2px#D1D2D4 !important
}

.dragColumn {
	cursor: all-scroll;
}

.fiter-badge {
	position: absolute !important;
	right: -6px;
	top: -8px !important;
	border-radius: 100%;
}

.xs-data-grid {
	height: 56px;
	padding: 8px 12px;
	background-color: white;
	display: flex;
	align-items: center;
	border-bottom: 1px solid var(--bs-gray-100);

	&:nth-child(even) {
		background-color: var(--bs-gray-50);
	}

	&:nth-child(odd) {
		background-color: var(--bs-white);
	}

	&:hover {
		background-color: #f3f7fd !important
	}
/* added for table mobile view */
}

.btn-clear {
	color: #474954;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
		color: #474954;
	}
}
@media (max-width:576px) {
	.services-search-container {
		width: 100%;
	}
}