.card_body {
    .border-bottom:last-child {
        border-bottom: 0px !important;
    }
}

.bay-select-container .bay-select__control {
    padding: 0px 8px;
}
.bay-select-container .bay-select__placeholder {
    margin-left: 8px;
}