.mls-page{
    .bay-data-grid .ka-cell:nth-child(2) {
        padding-left: 20px !important; 
    }
}
.enableValidate{
    border: solid 1px #dc3545 !important;
}
.viewCustomerTable{
    td,th{border-right:2px solid lightgray;border-left:2px solid lightgray}
}